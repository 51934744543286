@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';

.chat_div{
    width: 100vw;
    height: 92vh;
    max-height: 92vh;

    @include flex-column-start-center;
    background-color: $violet-primary;

}

.chat_div_main{
    width: 100%;
    height: 100%;

    @include flex-row-between-center;
    // overflow-y: hidden;
}

.chat_div_main_left{
    width: 40%;
    height: 100%;

    @include flex-row-center-center;
    box-shadow: inset 32rem 0 100px -100px $color-prompt;

    & img{
        max-width: 100%;
        height: 100%;
        padding: 0rem;
    }
}


.chat_div_main_right{
    width: 60%;
    height: 100%;
    @include flex-row-center-center;
    
    box-shadow: inset 20rem 0 60rem 80px $violet-primary;
    background: url('../../assets/imags/galaxy.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    filter: brightness(100%);
    box-shadow: 100px;

}

.chat_div_chat{
    height: 100%;
    width: 100%;
    @include flex-column-end-center;

    margin-bottom: 1rem;
}

.chat_div_chat_scroll{
    width: 90%;
    height: 86%;
    max-height: 86%;

    @include flex-column-end-center;
    padding: 0rem 0.5rem;
    margin-bottom: 1rem;
}

.chat_div_chat_scroll_100{
    width: 100%;
    height: auto;
    max-height: 100%;
    overflow-y: scroll;

    padding: 0rem 0.5rem;

}

.each_chat_div_parent_left{
    width: 100%;
    height: auto;
    @include flex-row-start-center;
    margin: 0.4rem 0rem;
}
.each_chat_div_parent_right{
    width: 100%;
    height: auto;
    @include flex-row-end-center;  
    margin: 0.4rem 0rem;
}

.each_chat_div_parent_center{
    width: 100%;
    height: 75vh;
    @include flex-row-center-center;  
    margin: 0.4rem 0rem;

    overflow-y: hidden;

}


.each_chat_div_left{
    max-width: 88%;
    min-height: 3rem;

    @include flex-row-start-center;
    gap: 1rem;

    padding: 0.5rem;
    margin: 0rem 0.5rem;
    border: 1px solid $violet-secondary;
    border-radius: 0rem 1rem 1rem 1rem;
    background-color:$violet-primary-shade;

}
.each_chat_div_left_profile{
    position: relative;
}

.each_chat_div_right{
    max-width: 88%;
    min-height: 3rem;

    @include flex-row-end-center;
    gap: 1rem;

    padding: 0.5rem;
    margin: 0rem 0.5rem;
    border: 1px solid $violet-secondary;
    border-radius: 1rem 1rem 0rem 1rem;
    background-color:$violet-primary-shade;
    
}



.chat_div_chat_icon{
    max-width: 3rem;
    min-width: 3rem;
    min-height: 3rem;
    max-height: 3rem;

    border: 3px solid $violet-light-shade;
    border-radius: 50%;

    overflow: hidden;

    & img{
        width: 100%;
        height: 100%;
    }
    
}
.active_status{
    position: absolute;
    top: 0.5rem;
    right: 0rem;

    width: 0.8rem;
    height: 0.8rem;
    background-color: $green-primary;
    border-radius: 50%;

    z-index: 1000;
}

.inactive_status{
    position: absolute;
    top: 0.5rem;
    right: 0rem;

    width: 0.8rem;
    height: 0.8rem;
    background-color: $gray-primary;
    border-radius: 50%;

    z-index: 1000;
}

.chat_div_chat_msg{
    font-size: 1rem;
    font-family: 'Quicksand Bold';  
    color: rgba(255, 255, 255, 0.80);

}
.text_spinner{
    width: 1.8rem;
    margin-right: 1rem;
}

.chat_div_chat_msg_time_status_div{
    max-width: 2rem;
    min-height: 2rem;

    margin-right: 0.4rem;

    @include flex-column-end-center;
    gap: 0.3rem;

}
.chat_div_chat_msg_time{
    font-size: 0.8rem;
    color: #ffffff80;
    

}
.chat_div_chat_msg_status{
    color: $green-primary;
    @include flex-row-center-center;
}

.chat_div_chat_msg_status_gray{
    color: rgba(255, 255, 255, 0.5);
    @include flex-row-center-center;
}


.input_div{
    width: 90%;
    height: 10%;
    max-height: 10%;

    @include flex-row-center-center;
    gap: 1rem;

    & input{
        width: 80%;
        height: 100%;
        background-color:transparent;

        border: 1px solid $violet-secondary;
        border-radius: 10rem;

        font-size: 1rem;
        font-family: "Quicksand Bold";
        color: rgba(255, 255, 255, 0.80);

        padding: 0rem 1rem;

    }
    & input:focus{
        border: 1px solid $violet-light;
        outline: none;
    }
    & input::placeholder {
        color: rgba(255, 255, 255, 0.60);
        opacity: 0.75;
    }
    & button{
        width: 20%;
        height: 95%;
        border-radius: 10rem;

        border: none;
        // background-color: $orange-light-shade;
        background-color: $green-primary-shade;

        font-size: 1.4rem;
        font-family: "League Spartan Bold";

        cursor: pointer;
        text-shadow:0 0 10px #fff, 0 0 10px #fff;

        @include flex-row-center-center;
  
    }
}


.input_div_inactive{
    width: 90%;
    height: 10%;
    max-height: 10%;

    font-size: 1rem;

    color: $seperator_background;

    @include flex-row-center-center;
    gap: 1rem;
}

.anchor_tag{
    font-size: 1rem;
    color: $green-primary;
}

.anchor_tag_secondary{
    font-size: 1rem;
    color: $green-primary-shade;
}